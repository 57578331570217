.vjs-big-play-centered {
	.vjs-big-play-button {
		border-radius: 50%;
		background-color: transparent;
		border: none;
		height: 178px;
		width: 178px;
		margin: 0;
		transform: translate(-50%, -50%);
		z-index: 9;
		.vjs-icon-placeholder {
			display: block;
			height: 178px;
			width: 178px;
			background: url(https://mygaygetaway.com/assets/images/play-white-outlined.svg) center
				no-repeat;
			&:before {
				display: none;
			}
		}
	}
	.vjs-control-bar {
		z-index: 9;
	}
}
