.react-calendar {
  &__tile ,
  &__navigation__label,
  &__navigation__arrow {
    background: none;
    border: none;
    text-align: center;
  }
  &__navigation {
    display: flex;
    padding: 8px 16px;
    &__label {
      flex: 1;
      &__labelText {
        color: #666;
      }
    }
    &__arrow {

    }
    &__prev-button,
    &__next-button {
      padding: 0;
      aspect-ratio: 1;
      width: 35px;
      height: 35px;
    }
    &__prev2-button,
    &__next2-button {
      display: none;
    }
    &__next-button {
      
    }
  }
  &__viewContainer {
    
  }
  &__month-view {

    &__weekdays {
      &__weekday {
        display: flex;
        justify-content: center;
      }
    }
    &__days {
      &__day {
        aspect-ratio: 1;
        width: calc(100% / 7);
        // font-weight: bold;
        &--neighboringMonth {

        }
        &--weekend {
          // color: #ff0000;
        }
      }
    }
  }
  &__tile {
    &--range {
    }
    &--active {
      background-color: #f0f0f0;
    }
    &--rangeStart,
    &--rangeEnd {
      background-color: #F97487;
      color: #fff;
    }
    &--rangeStart {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;

    }
    &--rangeEnd {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;

    }
    &--rangeBothEnds {
      border-radius: 4px;
    }
  }
}